import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { getComposedPath } from './elements';

export function getPath(e: UIEvent): Element[] {

  const composedPath = e?.composedPath() || e?.['path'];

  if (Array.isArray(composedPath)) {
    return composedPath as Element[];
  }

  const path: Element[] = [];

  let el = e?.target as Element;

  while (el) {
    path.push(el);
    el = el.parentNode as Element;
  }

  return path;
}


export function waitForNextHistoryJump(doFn: () => void, until?: Observable<any>): Subscription {

  let internalHandler: () => void;
  let _sub: Subscription = new Subscription(() => {
    window.removeEventListener('popstate', internalHandler);
  });

  internalHandler = () => {
    _sub?.unsubscribe();
    doFn();
    window.removeEventListener('popstate', internalHandler);
  };

  window.addEventListener('popstate', internalHandler);

  if (until) {
    _sub.add(
      until.pipe(take(1)).subscribe(() => {
        window.removeEventListener('popstate', internalHandler);
        _sub?.unsubscribe();
      })
    );
  }

  return _sub;
}

export interface FocusGroupListenerEvent {
  lastFocusInEvent: FocusEvent;
  lastFocusOutEvent: FocusEvent;
  type: 'focusgroupin' | 'focusgroupchange' | 'focusgroupout';
}

export interface FocusGroupEventListenerOptions {
  focusgroupin?: (e: FocusGroupListenerEvent) => void;
  focusgroupchange?: (e: FocusGroupListenerEvent) => void;
  focusgroupout?: (e: FocusGroupListenerEvent) => void;
  focusin?: (e: FocusEvent) => void;
  focusout?: (e: FocusEvent) => void;
}

/**
 * registers several event listener on a root element. The root element and its children create a focus group.
 *
 * "focusgroupin": dispatches if a user firstly focusing the root or an child element (*called asynchronously).
 * Similar to "focusin" but "focusgroupin" is not called if the focus changes from two elements within the group
 *
 * "focusgroupchange": dispatches if the focus changes within the group (*called asynchronously)
 *
 * "focusgroupout: dispatches when user firstly focusing an element outside the group" (*called asynchronously).
 * Similar to "focusout" but "focusgroupout" is not called if the focus changes from two elements within the group
 *
 * "focusin" and "focusout", original events
 *
 * returns "removeEventListener" - a function, which removes the event listener
 */
export function addFocusGroupEventListener(root: HTMLElement, options?: FocusGroupEventListenerOptions) {

  let inFlag = false;
  let outFlag = false;
  let couplerHandle: any;
  let curFocusInEvent: FocusEvent;
  let curFocusOutEvent: FocusEvent;

  const couplerFn = () => {
    couplerHandle = void 0;

    if (inFlag && !outFlag) {
      options?.focusgroupin?.({
        lastFocusInEvent: curFocusInEvent,
        lastFocusOutEvent: curFocusOutEvent,
        type: 'focusgroupin'
      });
    }

    if (inFlag && outFlag) {
      options?.focusgroupchange?.({
        lastFocusInEvent: curFocusInEvent,
        lastFocusOutEvent: curFocusOutEvent,
        type: 'focusgroupchange'
      });
    }

    if (!inFlag && outFlag) {
      options?.focusgroupout?.({
        lastFocusInEvent: curFocusInEvent,
        lastFocusOutEvent: curFocusOutEvent,
        type: 'focusgroupout'
      });
    }

    inFlag = false;
    outFlag = false;
    curFocusInEvent = null;
    curFocusOutEvent = null;
  };

  const fin = (e: FocusEvent) => {
    curFocusInEvent = e;
    options?.focusin?.(e);
    inFlag = true;
    if(!couplerHandle) {
      couplerHandle = setTimeout(() => couplerFn(), 0);
    }
  };

  const fout = (e: FocusEvent) => {
    curFocusOutEvent = e;
    options?.focusout?.(e);
    outFlag = true;
    if(!couplerHandle) {
      couplerHandle = setTimeout(() => couplerFn(), 0);
    }
  };

  root.addEventListener('focusin', fin);
  root.addEventListener('focusout', fout);

  const removeEventListener = () => {
    root.removeEventListener('focusin', fin);
    root.removeEventListener('focusout', fout);
  };


  return {
    removeEventListener,
  };
}

export interface FocusGroupEvent {
  focusEvent: FocusEvent;
  group: HTMLElement[];
  type: 'focusgroupin' | 'focusgroupchange' | 'focusgroupout';
  target: Element;
  oldTarget: Element;
}


export interface FocusGroupEventListenerResult {
  complete: () => void;
  isFocusInGroup$: Observable<boolean>;
  focusChangeInDocument$: Observable<Element>;
}

export interface FocusGroupCreationResult {
  complete: () => void;
  isFocusInGroup$: Observable<boolean>;
  focusChangeInDocument$: Observable<HTMLElement>;
  event$: Observable<FocusGroupEvent>;
  focusGroupIn$: Observable<FocusGroupEvent>;
  focusGroupOut$: Observable<FocusGroupEvent>;
  focusGroupChange$: Observable<FocusGroupEvent>;
}

let N = 0;

export function createFocusGroup(root: HTMLElement): FocusGroupCreationResult {

  let group: HTMLElement[] = [];
  let globalVarOldTarget = document.activeElement as HTMLElement;

  // const isFocusInGroup$ = new BehaviorSubject<boolean>(false);
  const focusChangeInDocument$ = new BehaviorSubject<HTMLElement>(document.activeElement as HTMLElement);
  const event$ = new BehaviorSubject<FocusGroupEvent>(null);

  // if this flag is true, then ignore the next 'focusgroupout' ONLY if the next 'focusInBodyHandler()' resolves to 'focusgroupout'
  let ignoreNextFocusgroupoutInNextFocusInBodyHandler = false;

  // if this flag is true, then ignore the next 'focusgroupin' ONLY if the next 'focusInBodyHandler()' resolves to 'focusgroupin'
  let ignoreNextFocusgroupintInNextFocusInBodyHandler = false;

  const myId = N++;

  /**
   * - mousedown events are more reliable to determin if the event target element is in the focus group or not
   * (some browser do not consider the mouse click on the scrollbar as a part of an element and trigger a focus change)
   * we use mousedown event to prepare ourselves to ignore a potential wrong focusgroupout
   */
  const mouseDownBodyHandler = (e: MouseEvent) => {
    const inGroup = isEventInGroup(e);

    if (inGroup) {
      // ignore the next 'focusgroupout' ONLY if the next 'focusInBodyHandler()' resolves to 'focusgroupout'
      ignoreNextFocusgroupoutInNextFocusInBodyHandler = true;
    }

  };

  // called everytime the focus changes within the document body
  const focusInBodyHandler = (e: FocusEvent) => {

    const oldTarget = globalVarOldTarget;
    const target = e.target as HTMLElement;
    const bluredElement = e.relatedTarget as HTMLElement;
    globalVarOldTarget = target;

    const newTargetIsInGroup = isEventInGroup(e);

    const focusWasInGroup = isElementInGroup(oldTarget);
    const sameTargets = oldTarget === target;

    if (sameTargets) {
      return false;
    }

    focusChangeInDocument$.next(target);

    const event: FocusGroupEvent = {
      focusEvent: e,
      group,
      type: null,
      target,
      oldTarget
    };


    if (newTargetIsInGroup) {
      if (focusWasInGroup) {
          event.type = 'focusgroupchange';
      } else {
        if (!ignoreNextFocusgroupintInNextFocusInBodyHandler) {
          event.type = 'focusgroupin';
        }
      }
    } else {
      if (focusWasInGroup) {
        if (!ignoreNextFocusgroupoutInNextFocusInBodyHandler) {
          event.type = 'focusgroupout';
        } else {
          // it should be ignored but the focus shift cannot be prevented so
          // focus on the old element if possible
          // this will resolve into a new 'focusInBodyHandler()' call, which would detect a 'focusgroupin'
          // which needs to be ignored as well
          if (bluredElement) {
            ignoreNextFocusgroupintInNextFocusInBodyHandler = true;
            bluredElement.focus();
          }
        }
      }
    }

    if (event.type) {
      event$.next(event);
    }

    // sets it always to false at the end of the function
    ignoreNextFocusgroupoutInNextFocusInBodyHandler = false;
    ignoreNextFocusgroupintInNextFocusInBodyHandler = false;
  };

  const isEventInGroup = (e: Event): boolean => {
    return e.composedPath().includes(root);
  };

  const isElementInGroup = (e: HTMLElement): boolean => {
    const path = getComposedPath(e);
    return path.includes(root);
  };

  const registerAll = () => {
    document.body.addEventListener('focusin', focusInBodyHandler);
    document.body.addEventListener('mousedown', mouseDownBodyHandler);
  };

  const removeAll = () => {
    document.body.removeEventListener('focusin', focusInBodyHandler);
    document.body.removeEventListener('mousedown', mouseDownBodyHandler);
  };

  const complete = () => {
    removeAll();
    focusChangeInDocument$.complete();
    event$.complete();
  };

  // call it to make listener synchronously
  registerAll();

  return {
    complete,
    isFocusInGroup$: focusChangeInDocument$.pipe(map(el => isElementInGroup(el), distinctUntilChanged())),
    focusChangeInDocument$: focusChangeInDocument$.asObservable(),
    event$: event$.pipe(filter(ev => !!ev)),
    focusGroupIn$: event$.pipe(filter(ev => ev?.type === 'focusgroupin')),
    focusGroupOut$: event$.pipe(filter(ev => ev?.type === 'focusgroupout')),
    focusGroupChange$: event$.pipe(filter(ev => ev?.type === 'focusgroupchange')),
  };
}

